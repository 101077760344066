<template>
	<InterviewsList></InterviewsList>
</template>

<script>
import InterviewsList from "@/components/shared/tools/interview/List.vue";

export default {
	name: "InterviewsListPage",

	components: {
		InterviewsList,
	},
};
</script>

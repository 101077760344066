<template>
	<div class="pa-2">
		<AppTable
			v-if="interviews.length"
			:headers="headers"
			:items="items"
			:actions="actions"
			:operation="operation"
			@create="createInterview()"
			@delete="deleteInterview"
			@explore="exploreInterview"
		></AppTable>

		<!-- If no interviews are created -->
		<NoData
			v-else
			heading="app.no_interviews_yet"
			@init="createInterview()"
		></NoData>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="onConfirmDeletion()"
		></TheConfirmation>
	</div>
</template>

<script>
import { mapState } from "vuex";
import TheConfirmation from "@/components/layout/TheConfirmation";
import AppTable from "@/components/ui/AppTable.vue";
import NoData from "@/components/shared/NoData.vue";
import moment from "moment";

export default {
	name: "InterviewsList",

	data() {
		return {
			moment,
			delete_dialog: false,
			to_delete_id: null,
		};
	},

	components: {
		TheConfirmation,
		AppTable,
		NoData,
	},

	mounted() {
		this.getInterviews();
	},

	computed: {
		...mapState({
			interviews: (state) => state.interview.list,
		}),

		headers() {
			return [
				"app.interview_no",
				"app.interviewees",
				"app.interview_date",
				"app.goal",
				"app.actions",
			];
		},

		items() {
			let result = [];
			let sorted_result = _.sortBy(this.interviews, [
				function(o) {
					return o.id;
				},
			]);
			sorted_result.forEach((item) => {
				result.push([
					item.id,
					item.interviewees
						.map((item) => item.name)
						.filter((n) => n)
						.join(", "),
					moment(item.date).format("DD/MM/YYYY"),
					item.goal,
				]);
			});

			return result;
		},

		actions() {
			return [
				{
					icon: "delete",
					color: "error",
					event_name: "delete",
				},
				{
					icon: "edit",
					color: "info",
					event_name: "explore",
				},
				{
					icon: this.$vuetify.rtl ? "left_arrow" : "right_arrow",
					color: "success",
					event_name: "explore",
				},
			];
		},

		operation() {
			return {
				event: "create",
			};
		},
	},

	methods: {
		async getInterviews() {
			try {
				this.$loader.start();

				await this.$store.dispatch("interview/list");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createInterview() {
			try {
				this.$loader.start();

				let today_date = new Date().toISOString().substr(0, 10);
				// Create interview
				let response = await this.$store.dispatch("interview/create", {
					interviewees: [{ name: null, role: null }],
					date: today_date,
					actual_date: today_date,
					goal: null,
					questions: [
						{ title: null, value: null },
						{ title: null, value: null },
					],
					additional_details: [
						{
							title: null,
							value: null,
						},
					],
				});

				// Push to newly created interview
				this.exploreInterview(response.id);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		exploreInterview(interview_id) {
			try {
				this.$loader.start();

				this.$router.push({
					name: "Interview",
					params: {
						id: interview_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		deleteInterview(interview_id) {
			try {
				this.$loader.start();

				this.to_delete_id = interview_id;

				this.delete_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onConfirmDeletion() {
			try {
				// Delete the Interview
				this.$store.dispatch("interview/delete", {
					interview_id: this.to_delete_id,
				});

				// Close the dialog
				this.delete_dialog = false;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
